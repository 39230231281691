import React from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
      <Box
        component="footer"
        sx={{
          bgcolor: "primary.main",
          color: "white",
          p: 2,
          textAlign: "center",
          mt: "auto", // Pushes the footer to the bottom
        }}
      >
        <Typography variant="body2">
          &copy; 2024 NetSuite Master Technical Consultant | All rights
          reserved.
        </Typography>
        <Typography variant="body2">
          <Link
            to="/privacy-policy"
            style={{ color: "white", textDecoration: "underline" }}
          >
            Privacy Policy
          </Link>
        </Typography>
      </Box>
    );
};

export default Footer;
