import React from "react";
import { AppBar, Toolbar, Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <AppBar position="static">
      <Toolbar>
        {/* Logo wrapped in a Link */}
        <Link
          to="/"
          style={{
            textDecoration: "none",
            display: "flex",
            alignItems: "center",
            color: "inherit",
          }}
        >
          <Box
            component="img"
            src="/netsuite-master-icon.png"
            alt="Netsuite Master Logo"
            sx={{ height: 40, marginRight: 2 }}
          />
          <Typography variant="h6" component="div">
            NetSuite Master
          </Typography>
        </Link>
        <Box sx={{ display: "flex", gap: 2, marginLeft: "auto" }}>
          <Link to="/" style={{ textDecoration: "none", color: "white" }}>
            Home
          </Link>
          <Link to="/about" style={{ textDecoration: "none", color: "white" }}>
            About
          </Link>
          <Link
            to="/contact"
            style={{ textDecoration: "none", color: "white" }}
          >
            Contact
          </Link>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
