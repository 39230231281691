import React, { useState } from "react";
import { Box, Typography, TextField, Button, Alert } from "@mui/material";
import { supabase } from "../supabaseClient"; // Ensure this is the correct path to your Supabase client

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone_number: "",
    message: "",
  });

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccess(false);
    setError("");

    const { data, error } = await supabase.from("contacts").insert([formData]);

    if (error) {
      console.error("Error inserting data:", error);
      setError("Failed to submit the form. Please try again.");
    } else {
      setSuccess(true);
      setFormData({ name: "", email: "", phone_number: "", message: "" });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "80vh",
        padding: "2rem",
      }}
    >
      <Typography variant="h4" gutterBottom>
        Contact Us
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        We’d love to hear from you! Reach out with any questions or project
        inquiries.
      </Typography>

      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: "400px",
          mt: 2,
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          name="name"
          label="Name"
          variant="outlined"
          fullWidth
          sx={{ mb: 2 }}
          value={formData.name}
          onChange={handleChange}
        />
        <TextField
          name="email"
          label="Email"
          variant="outlined"
          fullWidth
          sx={{ mb: 2 }}
          value={formData.email}
          onChange={handleChange}
        />
        <TextField
          name="phone_number"
          label="Phone Number"
          variant="outlined"
          fullWidth
          sx={{ mb: 2 }}
          value={formData.phone_number}
          onChange={handleChange}
        />
        <TextField
          name="message"
          label="Message"
          variant="outlined"
          multiline
          rows={4}
          fullWidth
          sx={{ mb: 2 }}
          value={formData.message}
          onChange={handleChange}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 1 }}
        >
          Submit
        </Button>
      </Box>

      {success && (
        <Alert severity="success" sx={{ mt: 3 }}>
          Form submitted successfully!
        </Alert>
      )}
      {error && (
        <Alert severity="error" sx={{ mt: 3 }}>
          {error}
        </Alert>
      )}
    </Box>
  );
};

export default Contact;
