import React from "react";
import { Box, Typography } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <Box
      sx={{
        padding: "2rem",
        maxWidth: "800px",
        margin: "auto",
        minHeight: "80vh",
      }}
    >
      <Typography variant="h4" gutterBottom>
        Privacy Policy
      </Typography>
      <Typography variant="body1" gutterBottom>
        At NetSuite Master, we take your privacy seriously. This privacy policy
        outlines how we collect, use, and protect your personal information.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Information We Collect
      </Typography>
      <Typography variant="body1" gutterBottom>
        - Name, email address, and other contact details you provide through our
        contact forms or lead forms.
        <br />
        - Usage data and analytics for improving our services.
        <br />- Any additional information you provide voluntarily.
      </Typography>

      <Typography variant="h6" gutterBottom>
        How We Use Your Information
      </Typography>
      <Typography variant="body1" gutterBottom>
        - To respond to inquiries or service requests.
        <br />
        - To improve our website and services.
        <br />- To send updates, marketing materials, and other relevant
        information (only if you consent).
      </Typography>

      <Typography variant="h6" gutterBottom>
        Data Security
      </Typography>
      <Typography variant="body1" gutterBottom>
        We implement appropriate technical and organizational measures to
        protect your personal data from unauthorized access, alteration,
        disclosure, or destruction.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Your Rights
      </Typography>
      <Typography variant="body1" gutterBottom>
        - You have the right to access, update, or delete your personal
        information.
        <br />- You can opt out of receiving marketing communications at any
        time.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Contact Us
      </Typography>
      <Typography variant="body1" gutterBottom>
        If you have any questions about this Privacy Policy, please contact us
        at <a href="mailto:ns.tech.guru@gmail.com">ns.tech.guru@gmail.com</a>.
      </Typography>
    </Box>
  );
};

export default PrivacyPolicy;
