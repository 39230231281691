import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { CssBaseline, Box, Container } from '@mui/material';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import PrivacyPolicy from './pages/PrivacyPolicy';

function App() {
    return (
      <Router>
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
          }}
        >
          {/* Navbar at the top */}
          <Navbar />

          {/* Main content, with a flex-grow property to fill available space */}
          <Box
            component="main"
            sx={{
              flex: "1 0 auto", // Grow and take up remaining space
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              padding: "2rem 0", // Additional padding to center content
            }}
          >
            <Container>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              </Routes>
            </Container>
          </Box>

          {/* Footer that sticks to the bottom */}
          <Footer />
        </Box>
      </Router>
    );
}

export default App;
